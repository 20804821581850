<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div
            class="studybox_h2_box"
            v-if="isTokenStillValid"
          >
            <h2>Set a New Password</h2>
          </div>
          <section class="study_myinfo_list_area">
            <div style="width:90%;margin:auto;">
              <section class="study_main_box">
                <div style="width:100%;">
                  <div class="signup_left_box"></div>
                  <div class="signup_right_box">
                    <div id="login_content">
                      <form
                        class="login_form"
                        @submit.prevent="validationForm"
                        v-if="isTokenStillValid"
                      >
                        <div class="login_form_box">
                          <input type="email" v-model="formData.email" class="login_input_box_01_round moon_email" placeholder="Email" alt="Email" readonly>
                          <input type="password" v-model="formData.password" class="login_input_box_01_round moon_pass" placeholder="New Password" alt="New Password">
                        </div>
                      </form>
                      <div
                        style="width:230px;margin:auto;text-align:left;"
                        v-if="isTokenStillValid"
                      >
                        <button type="button" class="btn loginBtn_sheet" @click="validationForm">
                          <a href="#">확인</a>
                        </button>
                      </div>
                      <h2
                        v-if="isTokenStillValid === false"
                        v-text="tokenErrorMessage"
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'notification' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'
import User from '@/models/User'

export default {
  name: 'ChangePassword',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  data () {
    return {
      isTokenStillValid: null,
      tokenErrorMessage: null,
      formData: {
        email: typeof this.$route.query.email !== 'undefined' ? this.$route.query.email : '',
        password: '',
        accessToken: '',
        tokenId: ''
      }
    }
  },
  mounted () {
    this.formData.accessToken = this.$route.params.accessToken
    this.formData.tokenId = this.$route.params.tokenId
    this.validateToken()
  },
  methods: {
    async validateToken () {
      this.isTokenStillValid = null
      const response = await User.validateToken({ tokenId: this.formData.tokenId })
      if (response.data.isTokenValid) {
        this.isTokenStillValid = true
      } else {
        this.tokenErrorMessage = response.data.message
        this.isTokenStillValid = false
      }
    },
    async validationForm () {
      try {
        const response = await User.changePassword(this.formData)
        if (response.data.success) {
          this.$swal({
            title: response.data.title,
            text: response.data.message,
            icon: 'success'
          }).then(() => {
            this.$router.push({ name: 'login', query: { email: this.formData.email } })
          })
        } else {
          this.$swal({
            title: 'Error',
            text: response.data.message,
            icon: 'error'
          })
        }
      } catch (e) {
        this.$swal({
          title: 'Error',
          text: e.response.data,
          icon: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
